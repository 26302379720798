$xl-break-point: 1200px;
$lg-break-point: 992px;
$md-break-point: 768px;
$sm-break-point: 576px;


@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Syne+Mono&display=swap');

/*Mailchimp*/
@import url('//cdn-images.mailchimp.com/embedcode/horizontal-slim-10_7.css');

#mc_embed_signup {
	margin: 0 !important;
	clear: left;
	font-size: 14px;
}

#mc-embedded-subscribe {
	background-color: #f87188 !important;
	font-weight: bold
}

#mc_embed_signup_scroll>label {
	font-size: 28px !important;
}

#mc-embedded-subscribe-form {
	padding: 25px 0 25px 0 !important;
}

#mce-EMAIL {
	font-size: 18px;
}

#mc-embedded-subscribe {
	font-size: 18px;
}

#mc-embedded-subscribe-form input {
	display: inline;
	width: auto;
	margin-right: 10px;
}






body {
	font-family: "Lora", "Georgia", "Times", serif;
	line-height: 2.2em;
	color: #212f4f;
	font-size: 1rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "Open Sans", "Helvetica Neue", "Helvetica", Arial, sans-serif;
	color: #212f4f;
}

h1 {
	font-weight: 800;
}

h2 {
	letter-spacing: 0.1rem;
	font-size: 2rem;
	font-family: "Open Sans", "Helvetica Neue", "Helvetica", Arial, sans-serif;
	font-weight: 700;
	display: inline-block;
	margin-bottom: 3rem;
}



.author {
	margin: 2em 0 2em 0;
	line-height: 1.5;
	font-size: 1.1rem;
	color: #212427;
	padding: 5rem 0;
	background-color: #f7f7f7;
}

.author-img {
	max-width: 200px;
}

.author-text {
	background-color: white;
	padding: 3rem;
}

.author-title {
	font-family: "Open Sans", "Helvetica Neue", "Helvetica", Arial, sans-serif;
	font-weight: 700;
	display: block;
}

.bold {
	font-weight: 700;
}

.card-body {
	font-family: "Open Sans", "Helvetica Neue", "Helvetica", Arial, sans-serif;
	line-height: 1.5rem;
}

.card-footer {
	border: none;
	padding: 1.5em 0;
	font-weight: 700;
	font-size: 1.3rem;
	letter-spacing: 0.1em;
	background-color: #f6f9fc;
	cursor: pointer;
	text-transform: uppercase;
	font-family: "Open Sans", "Helvetica Neue", "Helvetica", Arial, sans-serif;
	display: inline-block;
	width: 100%;
}

.card-footer:hover {
	background-color: #e6ebf1;
}

.card-img {
	margin: 1em 0;
}

.card-section {
	display: flex;
	justify-content: space-between;
	align-items: center;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
	margin: 1em;
	left: 0;
	right: 0;
	border: solid white;
	border-width: 13px;
}

.card-section:hover {
	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.card-title {
	margin: 0.7em 0;
}

.container {
	width: 80%;
}

.cover {
	margin-bottom: 2em;
}

.cover-shadow {
	box-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.5) !important;
}

.credit-card {
	width: 50%;
	margin: auto;
}

.dark-background {
	background-color: rgb(40, 55, 66);
}

.expired-url {
	margin: 10em auto;
	width: 50%;
	line-height: 1.2em;
	font-size: 1.1em;
	color: #868699;
}

.faq-answer {
	width: 80%;
	margin: 1.5rem auto;
	display: block !important;
	line-height: 1.5;
	text-align: justify;
	font-size: 1.2rem;
}

.faq-question {
	font-weight: bold;
	margin: 3rem auto auto auto;
	width: 60%;
	display: block !important;
	font-size: 1.3rem !important;
}

.features {
	/*box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);*/
	background-color: #fafafa;
	margin: auto;
	padding: 2rem;
	font-size: 1.25rem;
	font-family: "Open Sans", "Helvetica Neue", "Helvetica", Arial, sans-serif;
	letter-spacing: 0.05rem;
}

.features-block {
	margin-left: 3rem;
}

.for-who {
	margin: 0 auto 8rem auto;
	font-size: 1.3rem;
	padding: 2rem 0;
}

.for-who-title {
	text-align: center;
}

.footer {
	background-color: rgb(40, 55, 66);
	color: #ffffff;
}

.gems {
	font-size: 1.2em;
}

.gems-item {
	padding-top: 1.2em;
}

.gems-text {
	display: inline-block;
	line-height: 1.5;
	font-size: 1.1em;
}

.getbook {
	width: 50%;
	opacity: 0.5;
}

.highlight {
	color: #2EBCE7;
	background-color: #2ebce70f;
	font-style: normal;
	font-family: "Open Sans", "Helvetica Neue", "Helvetica", Arial, sans-serif;
}

.highlight-pink {
	/*color: #2EBCE7;*/
	font-weight: bold;
	text-transform: uppercase;
}

.home {
	/*margin-top: 4em;*/
}

.inline-title {
	font-weight: 600;
	font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
	text-transform: uppercase;
	letter-spacing: 0.1rem;
}

.inside-book {
	font-size: 1.2rem;
	margin-bottom: 8rem;
}

.inside-book-icon {
	width: 60%;
}

.inside-book-description,
.toc-description,
.why-math-description,
.math-topics-description {
	line-height: 1.5;
	font-size: 1.2rem;
	text-align: justify;
	width: 80%;
	margin: 0 auto 3rem;
}

.link-gumroad {
	color: inherit;
}

.link-gumroad:hover {
	text-decoration: none;
	color: inherit;
}

.look-inside {
	font-family: 'Bangers', cursive;
	font-size: 1.5em;
	color: #f87188;
}

.math-topics {
	margin-bottom: 8rem;
}

.math-topics-bullets {
	margin: 0 auto 8rem auto;
	font-size: 1.3rem;
	border: solid #c0c1cd;
	border-width: 0.3rem 0;
	padding: 2rem 0;
}

.mb-5 {
	margin-bottom: 1rem !important;
	line-height: 1.5em;
}

.modal-content {
	border: none;
}

.navbar {
	padding: 4rem 1rem;
	box-shadow: inset 0 0 0 2000px rgba(19, 24, 55, 0.2);
	display: block;
}

.navbar-brand {
	color: white !important;
	padding-left: 1rem;
	font-size: 1.4rem;
	font-weight: 800;
	letter-spacing: 0.08em;
	font-family: "Open Sans", "Helvetica Neue", "Helvetica", Arial, sans-serif;
}

.nav-title {
	color: white !important;
	font-weight: 500;
	margin: auto;
	overflow: hidden;
}

.nav-background {
	background-image: url('../images/background_cloud.jpg');
	animation-name: MOVE-BG;
	animation-duration: 100s;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
	position: absolute;
	top: 0;
	left: 0;
	width: 10000px;
	height: 20rem;
	z-index: -1;
}

.nav-subscribe {
	width: 45% !important;
	flex-direction: column !important;
	position: block;
}

.nav-subscribe-email {
	width: 60% !important;
	margin-bottom: 0.5em !important;
	border-radius: 1em !important;
	height: 2em !important;
	font-size: 16px !important;
}

.nav-subscribe-input {
	width: 35%;
	border-radius: 1em !important;
	height: 2em !important;
	font-size: 16px !important;
	text-transform: uppercase !important;
	padding-left: 1em;
	font-family: "Open Sans", "Helvetica Neue", "Helvetica", Arial, sans-serif;
}

.offer {
	margin: 0 0 8rem 0;
}

.offer-card {
	width: 100%;
}

.offer-code {
	color: #2EBCE7;
	background-color: #FFE600;
}

.offer-price {
	background-color: #5658720d;
	font-weight: 700;
	font-size: 1.5em;
	letter-spacing: 0.15rem;
	padding: 1em 0;
	position: relative;
	font-family: "Open Sans", "Helvetica Neue", "Helvetica", Arial, sans-serif;
}

.offer-text {
	margin: 2rem auto;
	font-size: 1.2rem;
	width: 80%;
	color: #9c9c9c;
}

.offer-title {
	font-size: 2.2rem;
	font-weight: 500;
	width: 60%;
	margin: 3rem auto;
	background-color: #f8f8f8;
	padding: 1rem;
	font-family: "Open Sans", "Helvetica Neue", "Helvetica", Arial, sans-serif;
	font-size: 1.8rem;
	line-height: 1.8rem;
}

.offer-subtitle {
	font-size: 1.5rem;
	margin: 3rem auto;
}

.payment {
	margin: 5em 0;
}

.payment-success {
	margin: 5em 0;
}

.plus-sign {
	display: inline;
	margin: 0px 0.7em;
	font-size: 3em;
	vertical-align: middle;
}

.price {
	font-size: 1.5rem;
	margin: 2rem 0;
	font-weight: bold;
	font-family: "Open Sans", "Helvetica Neue", "Helvetica", Arial, sans-serif;
}

.punchline {
	margin: 4rem 0 8rem 0;
}

.punchline-subtext {
	font-size: 1.5rem;
}

.rounded {
	border-radius: 20px !important;
}

.satisfied {
	width: 50%;
	line-height: 1.5;
	background-color: #f6f9fc;
	padding: 2rem 2rem 1rem 2rem;
	margin-top: -5rem;
	margin-bottom: 8rem;
}

.satisfied-title {
	font-weight: 600;
}

.save-money {
	background-color: #2EBCE7;
	color: white;
	opacity: 0.8;
	box-shadow: 1px 3px 4px 0px #5c5c5c;
	transform: rotate(325deg);
	position: absolute;
	bottom: 0;
	right: 0;
	max-width: 20%;
	letter-spacing: 0.01em;
	font-weight: 600;
	font-size: 0.9em;
	font-family: "Open Sans", "Helvetica Neue", "Helvetica", Arial, sans-serif;
}

.snowflake {
	position: absolute;
	right: 0;
	z-index: 1;
}

.star {
	margin: 0.5em
}

.star-gems {
	/*margin-right: 1em;*/
}

.star-gems-img {
	width: 1rem;
}

.strikediag {
	background: linear-gradient(to left top, transparent 45%, #2EBCE7 50%, #2EBCE7 52%, transparent 55%);
	z-index: 100;
}

.subscribe {
	font-size: 1.2em;
	margin: 8rem auto;
	padding: 3rem;
	position: relative;
	box-shadow: inset 0 0 0 2000px rgba(19, 24, 55, 0.8);
	overflow: hidden;
}

.subscribe-background {
	background-image: url('../images/background_cloud.jpg');
	animation-name: MOVE-BG;
	animation-duration: 100s;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
	position: absolute;
	top: 0;
	left: 0;
	width: 10000px;
	height: 25rem;
	z-index: -1;
}

.subscribe-container {
	font-size: 14pt;
	margin-top: 1em !important;
	flex-flow: row wrap;
	padding: 0;
	border-radius: 20px;
	color: white !important;
}

.subscribe-email-input-container {
	width: 100%;
}

.subscribe-email {
	width: 60% !important;
	margin-bottom: 0.5em !important;
	border-radius: 1em !important;
	height: 2em !important;
	font-size: 16px !important;
	padding: 0 0 0.2rem 1rem !important;
	height: 2.3rem !important;
}

.subscribe-input {
	width: 60% !important;
	border-radius: 1em !important;
	height: 2em !important;
	font-size: 16px !important;
	text-transform: uppercase !important;
	font-family: "Open Sans", "Helvetica Neue", "Helvetica", Arial, sans-serif;
	height: 2.2rem !important;
}

.subscribe-input-container {
	width: 100%;
}

.subscribe-title {
	width: 100%;
	font-family: "Open Sans", "Helvetica Neue", "Helvetica", Arial, sans-serif;
	text-transform: uppercase;
	font-weight: 600 !important;
	width: 65%;
	margin: auto;
	letter-spacing: 0.1rem;
}

.title {
	text-align: center;
	margin: 2em;
}

.title-feature {
	font-weight: bold;
}

.toc {
	margin-bottom: 8rem;
}

.toc-card,
.toc-card-clickable {
	/*box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);*/
	border: none;

}

.toc-card-clickable:hover {
	/*box-shadow: 0 4px 8px 2px rgba(0,0,0,0.2);*/
}

.toc-card-header {
	background-color: #ffffff;
	border: none;
}

.toc-card-header>a:before {
	font-family: FontAwesome;
	content: "\f07c";
	padding-right: 5px;
}

.toc-card-header>a.collapsed:before {
	content: "\f07b";
}

.toc-chapter {
	margin: 1.5rem 0 0 1rem;
	display: inline;
	cursor: pointer;
}

.toc-chapter:hover {
	color: gray;
}

.toc-part {
	margin: 5rem 0 1rem 0;
}

.toc-part-description {
	margin: 0 auto 1rem auto;
	font-size: 1.1rem;
	width: 65%;
	line-height: 1.5;
	text-align: justify;
}

.toc-sections {
	margin-left: 5rem;
}

.underline {
	border-bottom: 0.4rem solid #f87188;
	padding-bottom: 0.5rem;
}

.wait {
	margin: 20% auto;
	width: 10%;
}

.wave {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.white {
	color: rgb(255, 255, 255);
}

.why-math {
	margin-bottom: 8rem;
	font-size: 1.2rem;
}

.why-math-description {
	width: 100%;
}


@keyframes MOVE-BG {
	from {
		transform: translateX(0);
	}

	to {
		transform: translateX(-10%);
	}
}




.carousel-control-prev-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='gray' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}

.carousel-control-next-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='gray' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}




.modal-90w {
	width: 90%;
}

.modal-dialog {
	max-width: 60%;
}


























@media screen and (min-width: $sm-break-point) and (max-width: 767px) {
	h1 {
		font-size: 2em;
	}

	#mc_embed_signup {
		width: auto !important;
	}

	.nav-subscribe {
		width: 80% !important;
		margin-left: 1em;
	}

	.nav-subscribe-input {
		width: 40% !important;
	}

}

@media screen and (min-width: 0) and (max-width: $md-break-point) {
	#mc_embed_signup .button {
		width: auto !important;
	}

	.author-img {
		margin-bottom: 1em;
	}

	.container {
		padding: 0;
	}

	.features-block {
		margin-left: 0;
	}

	.save-money {
		max-width: 30%;
		font-size: 0.7em;
	}

}

@media screen and (min-width: 0) and (max-width: $sm-break-point) {
	body {
		line-height: 1.5;
	}

	h1 {
		font-size: 1.75rem;
	}

	h2 {
		font-size: 1.75rem;
	}

	h3 {
		font-size: 1.5rem;
	}

	h4 {
		font-size: 1.25rem;
	}

	h5 {
		font-size: 1rem;
	}


	.author {
		padding: 1rem 0;
	}

	.inside-book {
		font-size: 1.1em;
	}

	.card-body {
		padding: 0;
	}

	.col-8-sm {
		max-width: 100%;
	}

	.container {
		padding: 1rem 0;
		margin-left: auto;
		margin-right: auto;
		width: 95%
	}

	.expired-url {
		width: 100%;
		font-size: 0.9em;
	}

	.faq-answer {
		word-break: break-word;
	}

	.features-block {
		margin-left: 0;
	}

	.for-who {
		width: 100%;
		margin-bottom: 3rem;
	}

	.for-who-element {
		margin-top: 1em;
	}

	.gems-text {
		font-size: 1.25rem;
	}

	.getbook {
		width: 100%;
	}

	.inside-book {
		margin-bottom: 4rem;
	}

	.inside-book-icon {
		width: 30%;
	}

	.math-topics {
		margin-bottom: 1rem;
	}

	.math-topics-bullets {
		margin-bottom: 4rem;
	}

	.modal-dialog {
		max-width: 90%;
		margin: auto;
	}

	.navbar {
		padding: 2rem 0rem;
	}

	.navbar-brand {
		letter-spacing: 0.01em;
		font-size: 1.1em;
		white-space: normal;
		line-height: 1.3em;
	}

	.nav-subscribe {
		width: 95% !important;
		margin: auto;
		font-size: 0.7em;
	}

	.nav-subscribe-input {
		width: 40% !important;
	}

	.offer-card {
		margin-top: 3rem;
	}

	.plus-sign {
		margin: 0.5rem;
	}

	.punchline {
		margin-bottom: 1rem;
	}

	.punchline-subtext {
		font-size: 1.25rem;
	}

	.release-date {
		width: 100%;
	}

	.row {
		margin-left: auto;
		margin-right: auto;
	}

	.save-money {
		max-width: 30%;
		font-size: 0.7em;
		bottom: -10px;
		color: #2EBCE7;
		background-color: #FFE600;
	}

	.subscribe {
		padding: 0;
		margin-bottom: 1rem;
	}

	.subscribe-email {
		width: 80%;
	}

	.title-nav {
		font-size: 1em;
		font-weight: 300;
	}

	.toc {
		width: 100%;
		margin-bottom: 2rem;
	}

	.toc-img {
		width: 50%;
	}

	.why-math {
		width: 95%;
		margin: auto;
	}

	.why-math-description,
	.math-topics-description,
	.inside-book-description,
	.toc-part-description {
		text-align: left;
		width: 95%;
	}
}

@media screen and (min-width: $sm-break-point) and (max-width: $lg-break-point) {
	.container {
		max-width: 100%;
	}

	.for-who {
		width: 100%;
	}

	.getbook {
		width: 80%;
	}

	.modal-dialog {
		max-width: 80%;
		margin: auto;
	}

	.star-gems {
		margin-right: 0.3em;
	}
}


@media screen and (min-width: $lg-break-point) and (max-width: $xl-break-point) {

	.save-money {
		max-width: 30%;
		font-size: 0.7em;
	}
}